import AgencySite from '@/mixins/AgencySite';
import { ONLY_MEMBERS_COUNTRIES } from '@/settings/Country';
import store from '@/store';

export default {
	mixins: [AgencySite],
	data() {
		return {
			redirectType: 'customer',
			showStoreModal: false,
		};
	},
	computed: {
		showPublicStore() {
			// Validate if country can only register members
			const onlyMembers = ONLY_MEMBERS_COUNTRIES.includes(this.registerCountry);
			const hideForReplicated = this.$replicated.replicatedId() ? store.getters['replicated/replicatedHideStore'] : !this.$user.auth();

			return ((this.$replicated.replicatedId() || this.$user.auth()) || this.isAgencySite) && !onlyMembers && !hideForReplicated;
		},
		showBuyNow() {
			const hideForReplicated = this.$replicated.replicatedId() ? store.getters['replicated/replicatedHideStore'] : false;
			return !ONLY_MEMBERS_COUNTRIES.includes(this.registerCountry) && !hideForReplicated;
		},
	},
	methods: {
		becomeCustomer() {
			if (this.showPublicStore) {
				this.scrollTo('our-products');
			} else {
				this.redirectType = 'customer';
				this.showStoreModal = true;
			}
		},
		becomeMember() {
			// Validate if country can only register members
			const validReplicated = this.$replicated.replicatedId();

			if (this.showPublicStore || validReplicated) {
				this.$router.push({ name: 'Register' });
			} else {
				this.redirectType = 'member';
				this.showStoreModal = true;
			}
		},
	},
};
