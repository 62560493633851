var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"h5"},[_vm._v(" "+_vm._s(_vm.translate(_vm.paymentMethod.name))+" ")]),_c('div',{staticClass:"row mb-2"},[(_vm.billing.country_code !== '' && !_vm.loadingState)?_c('div',{staticClass:"col-auto"},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.billing.first_name)+" "+_vm._s(_vm.billing.last_name))])]),(_vm.formatCountryAddress.address_form_type_2.includes(_vm.country))?[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address)+", "+_vm._s(_vm.address2FormatInType2)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address3)+" ")])]:[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address2)+" ")])],_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.city)+", "+_vm._s(_vm.billing.region_id)+", "+_vm._s(_vm.billing.postcode)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translate(_vm.billing.country_code.toLowerCase()))+" ")]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{class:{
						'col-auto': !_vm.isSplitPayment && !['xs', 'sm'].includes(_vm.windowWidth),
						'col-12': ['xs', 'sm'].includes(_vm.windowWidth),
						'mt-2': ['xs', 'sm'].includes(_vm.windowWidth)
					}},[_c('p',{staticClass:"mb-0"},[(_vm.isSplitPayment)?_c('b',[_vm._v(_vm._s(_vm.translate('main_credit_card')))]):_vm._e()]),(_vm.isCreditCard)?_c('p',{staticClass:"mb-0 d-inline"},[_c('img',{staticClass:"my-auto mr-1",staticStyle:{"width":"45px","height":"30px"},attrs:{"src":require(("@/assets/images/common/cc/logos/" + _vm.cardLogo + ".png")),"alt":"Card Logo"}}),_c('span',{staticClass:"text-muted"},[_vm._v(" ****"+_vm._s(_vm.paymentMethod.last_digits.length > 0 ? _vm.paymentMethod.last_digits : ''))])]):_vm._e(),_c('p',{staticClass:"mb-0 d-inline",class:{ 'ml-2': _vm.isCreditCard }},[(_vm.isSplitPayment)?_c('b',[_vm._v(_vm._s(_vm.displayedSplitAmount1))]):_vm._e()])]),[(_vm.isSplitPayment)?_c('b-col',{staticClass:"col-12 mb-2",class:{
							'col-md-6': !['xs', 'sm'].includes(_vm.windowWidth),
							'mt-2': ['xs', 'sm'].includes(_vm.windowWidth)
						}},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.translate('additional_credit_card')))])]),_c('p',{staticClass:"mb-0 d-inline"},[_c('img',{staticClass:"my-auto mr-1",staticStyle:{"width":"45px","height":"30px"},attrs:{"src":require(("@/assets/images/common/cc/logos/" + _vm.cardLogo2 + ".png")),"alt":"Card Logo"}}),_c('span',{staticClass:"text-muted"},[_vm._v(" ****"+_vm._s(_vm.paymentMethod.last_digits_2.length > 0 ? _vm.paymentMethod.last_digits_2 : ''))])]),_c('p',{staticClass:"mb-0 d-inline ml-2"},[_c('b',[_vm._v(_vm._s(_vm.displayedSplitAmount2))])])]):_vm._e()]],2)],2):_c('div',{staticClass:"col-12 fade-in text-center d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"h2"},[_c('i',{staticClass:"fa fa-fw fa-spinner fa-pulse"}),_c('h4',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.translate('loading'))+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }