<template>
	<div class="row mb-2">
		<div class="col-auto">
			<p class="mb-0">
				<b>{{ form.first_name }} {{ form.last_name }}</b>
			</p>
			<!-- <p class="mb-0">
				{{ translate(form.gender) }}
			</p> -->
			<p class="mb-0">
				<i class="far fa-envelope mr-2" />
				{{ form.email }}
			</p>
			<!-- <p class="mb-0">
				<i class="fas fa-birthday-cake mr-2" />
				{{ $moment(form.birthdate).format(MMMDY_FORMAT) }}
			</p> -->
			<p class="mb-0">
				<i class="fas fa-mobile-alt mr-2" />
				{{ form.mobile_number }}
				<i
					v-if="isPhoneVerified"
					class="fas fa-check-circle verified-icon" />
			</p>
			<p
				v-if="form.username"
				class="mb-0">
				<i class="fas fa-user mr-2" />
				{{ form.username }}
			</p>
		</div>
	</div>
</template>
<script>
import CommonMix from '../../mixins/Common';

export default {
	name: 'PersonalInformationOverview',
	mixins: [CommonMix],
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				// gender: '',
				email: '',
				// birthdate: '',
				username: '',
				mobile_number: '',
			},
			isPhoneVerified: false,
		};
	},
	created() {
		const personalInfoData = this.getStepInformation('CheckoutPersonalInformation');
		const { personal_information: personalInformation } = personalInfoData;

		if (personalInfoData.verification_phone_token) {
			this.isPhoneVerified = true;
		}

		this.path = personalInfoData.path;
		let form = { ...this.form };
		if (this.path === 'guest') {
			form = {
				first_name: this.form.first_name,
				last_name: this.form.last_name,
				email: this.form.email,
				mobile_number: this.form.mobile_number,
			};
		}
		Object.keys(form).forEach((property) => {
			if (personalInformation[property] !== '') {
				this.form[property] = personalInformation[property];
			}
		});
	},
};
</script>
<style scoped>
.verified-icon::before {
	color: #4cb22a !important;
}
</style>
