<template>
	<div class="row mt-3">
		<div class="col d-flex justify-content-center">
			<div
				v-if="!loading && !isAgencySite"
				class="alert alert-info text-center h5 w-100">
				<span>{{ translate('selected_sponsor') }}: </span><br>
				<span class="font-weight-bold">{{ sponsor.name }} </span><br>
				<!-- <span class="font-weight-bold">({{ translate('id_word') }}: {{ sponsor.id }})</span> -->
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="!loading" />
		</div>
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import CommonMix from '../../mixins/Common';
import AgencySite from '@/mixins/AgencySite';

export default {
	name: 'SponsorOverview',
	components: { IsLoading },
	mixins: [CommonMix, AgencySite],
	data() {
		return {
			sponsor: {
				id: '',
				name: '',
			},
		};
	},
	computed: {
		loading() {
			return !this.sponsor.name;
		},
	},
	mounted() {
		let sponsor = this.$replicated.replicatedSponsor();
		if (!sponsor) {
			sponsor = this.getStepInformation('CheckoutPersonalInformation').sponsor;
		}

		if (sponsor) {
			this.getSponsorInfo(sponsor).then((response) => {
				Object.keys(this.sponsor).forEach((value) => {
					this.sponsor[value] = response[value];
				});
			});
		}
	},
};
</script>
