<template>
	<div>
		<div class="h5">
			{{ translate(paymentMethod.name) }}
		</div>
		<div class="row mb-2">
			<div
				v-if="billing.country_code !== '' && !loadingState"
				class="col-auto">
				<p class="mb-0">
					<b>{{ billing.first_name }} {{ billing.last_name }}</b>
				</p>
				<template v-if="formatCountryAddress.address_form_type_2.includes(country)">
					<p class="mb-0">
						{{ billing.address }}, {{ address2FormatInType2 }}
					</p>
					<p class="mb-0">
						{{ billing.address3 }}
					</p>
				</template>
				<template v-else>
					<p class="mb-0">
						{{ billing.address }}
					</p>
					<p class="mb-0">
						{{ billing.address2 }}
					</p>
				</template>
				<p class="mb-0">
					{{ billing.city }}, {{ billing.region_id }}, {{ billing.postcode }}
				</p>
				<p class="mb-0">
					{{ translate(billing.country_code.toLowerCase()) }}
				</p>
				<b-row class="mt-2">
					<b-col
						:class="{
							'col-auto': !isSplitPayment && !['xs', 'sm'].includes(windowWidth),
							'col-12': ['xs', 'sm'].includes(windowWidth),
							'mt-2': ['xs', 'sm'].includes(windowWidth)
						}">
						<p class="mb-0">
							<b v-if="isSplitPayment">{{ translate('main_credit_card') }}</b>
						</p>
						<p
							v-if="isCreditCard"
							class="mb-0 d-inline">
							<img
								:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
								class="my-auto mr-1"
								alt="Card Logo"
								style="width:45px; height:30px;">
							<span class="text-muted"> ****{{ paymentMethod.last_digits.length > 0 ? paymentMethod.last_digits : '' }}</span>
						</p>
						<p
							:class="{ 'ml-2': isCreditCard }"
							class="mb-0 d-inline">
							<b v-if="isSplitPayment">{{ displayedSplitAmount1 }}</b>
						</p>
					</b-col>
					<template>
						<b-col
							v-if="isSplitPayment"
							:class="{
								'col-md-6': !['xs', 'sm'].includes(windowWidth),
								'mt-2': ['xs', 'sm'].includes(windowWidth)
							}"
							class="col-12 mb-2">
							<p class="mb-0">
								<b>{{ translate('additional_credit_card') }}</b>
							</p>
							<p class="mb-0 d-inline">
								<img
									:src="require(`@/assets/images/common/cc/logos/${cardLogo2}.png`)"
									class="my-auto mr-1"
									alt="Card Logo"
									style="width:45px; height:30px;">
								<span class="text-muted"> ****{{ paymentMethod.last_digits_2.length > 0 ? paymentMethod.last_digits_2 : '' }}</span>
							</p>
							<p
								class="mb-0 d-inline ml-2">
								<b>{{ displayedSplitAmount2 }}</b>
							</p>
						</b-col>
					</template>
				</b-row>
			</div>
			<div
				v-else
				class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
				<div class="col-12">
					<div class="h2">
						<i class="fa fa-fw fa-spinner fa-pulse" />
						<h4 class="mt-3">
							{{ translate('loading') }}
						</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { AVAILABLECC, CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import Country from '@/util/Country';
import CommonMix from '../../mixins/Common';
import { COUNTRY_ADDRESS_FORM_TYPES } from '@/settings/AddressForm';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'PaymentOverview',
	mixins: [CommonMix, WindowSizes],
	props: {
		cartTotal: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			stateData: new Country(),
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				region_id: '',
				region_name: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
			},
			formatCountryAddress: COUNTRY_ADDRESS_FORM_TYPES,
			CREDIT_CARD_FORM_METHODS,
		};
	},
	computed: {
		address2FormatInType2() {
			const numbers = this.billing.address2.split(',');
			if (numbers.length > 1) {
				return `Ext. ${numbers[0]} - Int. ${numbers[1]}`;
			}
			return `Ext. ${numbers[0]}`;
		},
		loadingState() {
			return this.stateData.data.loading;
		},
		cardLogo() {
			return this.getCardLogo(this.paymentMethod.card_type);
		},
		cardLogo2() {
			return this.getCardLogo(this.paymentMethod.card_type_2);
		},
		isSplitPayment() {
			return !!this.paymentMethod.last_digits_2;
		},
		splitAmount1() {
			return this.paymentMethod.split_amount;
		},
		splitAmount2() {
			return this.cartTotal - this.splitAmount1;
		},
		displayedSplitAmount1() {
			return this.formatAmount(this.splitAmount1);
		},
		displayedSplitAmount2() {
			return this.formatAmount(this.splitAmount2);
		},
		isCreditCard() {
			return CREDIT_CARD_FORM_METHODS.includes(this.paymentMethod.name);
		},
	},
	created() {
		const paymentInformation = this.getStepInformation('CheckoutPayment');
		const { billing: billingAddress } = paymentInformation.payment;

		const paymentMethod = { ...paymentInformation.card_data };
		Object.keys(paymentMethod).forEach((element) => {
			this.paymentMethod[element] = paymentMethod[element];
		});
		this.paymentMethod.name = paymentInformation.payment.payment_method.name;
		this.paymentMethod.split_amount = paymentInformation.payment.payment_method.split_amount ?? null;

		Object.keys(billingAddress).forEach((element) => {
			this.billing[element] = billingAddress[element];
		});
		if (typeof this.billing.region_name !== 'undefined' && this.billing.region_name !== '') {
			this.billing.region_id = this.billing.region_name;
		} else {
			this.stateData.getState(this.billing.region_id).then((state) => {
				this.billing.region_id = state.attributes.name;
			});
		}

		this.billing.country_code = this.billing.country_code.toLowerCase();
	},
	methods: {
		getCardLogo(cardType) {
			if (cardType.length > 0 && AVAILABLECC.includes(cardType)) {
				return cardType;
			}
			return 'cc';
		},
		formatAmount(amount) {
			return `$${amount.toFixed(2)} USD`;
		},
	},
};
</script>
