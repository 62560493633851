<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2">
		<template
			v-if="form.country_code !== '' && !loadingState">
			<div
				:class="{ 'col-md-6': [customer].includes(selectedPath) }"
				class="col-12">
				<p class="mb-0">
					<b>{{ form.first_name }} {{ form.last_name }}</b>
				</p>
				<p class="mb-0">
					<i class="fas fa-mobile-alt mr-0" />
					{{ form.mobile_number }}
				</p>
				<template v-if="formatCountryAddress.address_form_type_2.includes(form.country_code)">
					<p class="mb-0">
						{{ form.address }}, {{ address2FormatInType2 }}
					</p>
					<p class="mb-0">
						{{ form.address3 }}
					</p>
					<p class="mb-0">
						{{ form.address4 }}
					</p>
					<p class="mb-0">
						{{ form.city }}, {{ form.region_id }}, {{ form.postcode }}
					</p>
				</template>
				<template v-else>
					<p class="mb-0">
						{{ form.address }}
					</p>
					<p class="mb-0">
						{{ form.address2 }}
					</p>
					<p class="mb-0">
						{{ form.city }}, {{ form.region_id }}, {{ form.postcode }}
					</p>
				</template>
				<p class="mb-0">
					{{ translate(form.country_code.toLowerCase()) }}
				</p>
				<template v-if="pickupAtOffice">
					<span class="badge badge-primary">
						{{ translate('pickup_at_office') }}
					</span>
				</template>
				<div
					v-else-if="Object.keys(shippingMethodInfo).length && shippingMethodInfo.name.length"
					class="mt-1 mb-0">
					<img
						v-if="shippingMethodInfo.image"
						:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
						class="my-auto border"
						style="width: 45px; height: 30px;">
					<p class="text-muted d-inline">
						<span
							v-if="shippingMethodInfo.name"
							class="ml-1">
							{{ translate(shippingMethodInfo.name) }}
						</span>
						<small
							v-if="deliveryDays.length"
							class="font-italic">
							<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
						</small>
					</p>
					<br>
					<small
						v-if="shippingNotice.length"
						class="font-italic"
						v-html="translate(shippingNotice)" />
				</div>
			</div>
		</template>
		<div
			v-else
			:class="{ 'col-md-6': [customer].includes(selectedPath) }"
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
		<div
			v-if="[customer].includes(selectedPath) && !noAutoshipCountries.includes(country)"
			:class="{ 'border-left': !['xs','sm'].includes(windowWidth), 'mt-3': ['xs','sm'].includes(windowWidth) }"
			class="col-12 col-md-6">
			<p
				v-if="!loadingProducts"
				class="mb-0">
				<b>{{ translate('autoship_checkout') }}</b>
			</p>
			<div
				v-if="loadingProducts"
				class="h-100 d-flex justify-content-center align-items-center">
				<div class="h2 text-center">
					<em class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
			<template v-else>
				<template v-if="Object.keys(autoshipProducts).length">
					<selected-products-overview
						:products="displayedProducts"
						compact
						class="mt-2 mb-3" />
					<p class="font-italic text-small mb-0 mt-1">
						{{ translate('autoship_disclaimer_checkout') }}
					</p>
				</template>
				<b-alert
					v-else
					class="mt-2"
					show
					variant="warning">
					{{ translate('autoship_not_enabled') }}
				</b-alert>
			</template>
		</div>
		<b-col
			v-if="showShippingAlert && !pickupAtOffice"
			cols="12"
			class="mt-2">
			<div>
				<b-alert
					show
					variant="warning"
					class="overflow-hidden pt-2">
					<b-row no-gutters>
						<b-col v-html="translate('shippint_alert_lites')" />
					</b-row>
				</b-alert>
			</div>
		</b-col>
	</div>
</template>
<script>
import SelectedProductsOverview from '@/components/SelectedProductsOverview';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { SIMPLE_PRODUCTS_PARENT_PLACEHOLDER, SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import { customer } from '@/settings/Roles';
import { NO_AUTOSHIP_COUNTRIES } from '@/settings/Autoship';
import {
	SHIPPING_METHOD_CODES as shippingMethods,
} from '@/settings/Shipping';
import Country from '@/util/Country';
import Products from '@/util/Products';
import CommonMix from '../../mixins/Common';
import store from '../../store';
import { COUNTRY_ADDRESS_FORM_TYPES } from '@/settings/AddressForm';

export default {
	name: 'ShippingOverview',
	components: { SelectedProductsOverview },
	mixins: [CommonMix, ConfigurableProducts, WindowSizes],
	props: {
		shippingMethod: {
			type: String,
			default: '',
		},
		showShippingAlert: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			stateData: new Country(),
			form: {
				first_name: '',
				last_name: '',
				mobile_number: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				region_name: '',
			},
			shippingMethods,
			autoshipProducts: {},
			prodData: new Products(),
			customer,
			noAutoshipCountries: NO_AUTOSHIP_COUNTRIES,
			formatCountryAddress: COUNTRY_ADDRESS_FORM_TYPES,
			pickupAtOffice: null,
		};
	},
	computed: {
		products() {
			try {
				return this.prodData.data.response.data.data
					.filter((item) => item.attributes.available_for_autoship)
					.sort((a, b) => {
						const aIndex = sortList.indexOf(a.attributes.sku);
						const bIndex = sortList.indexOf(b.attributes.sku);
						if (aIndex === -1) return 1;
						if (bIndex === -1) return -1;
						return aIndex - bIndex;
					})
					.reduce((accumulator, product) => {
						if (!product.attributes.has_configurations) {
							accumulator.push({
								sku: product.attributes.sku,
								code_name: product.attributes.code_name,
								parent_sku: null,
								parent_code_name: null,
							});
						} else {
							Object.keys(this.getProductConfigs(product)).forEach((config) => {
								const configuredProduct = this.getConfiguredProduct(product, config);
								accumulator.push({
									sku: configuredProduct.sku,
									code_name: configuredProduct.code_name,
									parent_sku: product.attributes.sku,
									parent_code_name: product.attributes.code_name,
								});
							});
						}
						return accumulator;
					}, []);
			} catch (error) {
				return {};
			}
		},
		address2FormatInType2() {
			const numbers = this.form.address2.split(',');
			if (numbers.length > 1) {
				return `Ext. ${numbers[0]} - Int. ${numbers[1]}`;
			}
			return `Ext. ${numbers[0]}`;
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		loadingState() {
			return this.stateData.data.loading;
		},
		shippingMethodInfo() {
			try {
				if (this.shippingMethod === '') {
					return {};
				}
				const shippingMethod = { ...this.shippingMethods[this.shippingMethod] };
				shippingMethod.name = this.shippingMethod;
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		selectedPath() {
			if (store.getters.getCompletedSteps().includes(0)) {
				return store.state.CheckoutPersonalInformation.path;
			}
			return false;
		},
		displayedProducts() {
			return Object.entries(this.autoshipProducts).reduce((accumulator, [sku, item]) => {
				const parentCodeName = item.parent_code_name || SIMPLE_PRODUCTS_PARENT_PLACEHOLDER;

				if (item.value !== 0) {
					accumulator[parentCodeName] = {
						...accumulator[parentCodeName],
						[item.code_name]: {
							qty: item.value,
							sku,
						},
					};
				}

				return accumulator;
			}, {});
		},
	},
	mounted() {
		const shippingInfo = this.getStepInformation('CheckoutShipping');
		const { shipping: savedShipping } = shippingInfo;
		this.pickupAtOffice = savedShipping.pickup_at_office;
		if (typeof savedShipping.autoship !== 'undefined') {
			this.prodData.getProducts(this.getStoredCountry(), undefined, { isRegister: true }).then(() => {
				this.products.forEach((product) => {
					const { sku } = product;
					if (typeof savedShipping.autoship.products[sku] !== 'undefined') {
						this.$set(this.autoshipProducts, sku, {
							...product,
							value: savedShipping.autoship.products[sku],
							required: true,
						});
					} else {
						this.$set(this.autoshipProducts, sku, {
							...product,
							value: 0,
							required: false,
						});
					}
				});
			});
		}
		const form = { ...this.form };
		Object.keys(form).forEach((property) => {
			if (savedShipping.shipping_address[property] !== '') {
				this.form[property] = savedShipping.shipping_address[property];
				if (property === 'region_id') {
					if (typeof savedShipping.shipping_address.region_name !== 'undefined' && savedShipping.shipping_address.region_name !== '') {
						this.form[property] = savedShipping.shipping_address.region_name;
					} else {
						this.stateData.getState(this.form[property]).then((state) => {
							this.form[property] = state.attributes.name;
						});
					}
				}
			}
		});
	},
};
</script>
