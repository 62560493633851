<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col-12">
				<form
					@submit.prevent="validateForm()"
					@keydown="clearValidationError($event.target.name);"
					@change="clearValidationError($event.target.name);">
					<li
						v-if="showSponsor"
						class="list-group-item border-left-0 border-right-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div class="mb-2">
									<span class="h4">{{ translate('sponsor_information') }} </span><span class="h4 text-danger">*</span>
									<span
										v-b-tooltip.hover.html.right
										:title="translate('sponsor_description', {company: companyName, supportLink: companyEmail, support: companyEmail })"
										class="text-muted small mx-2">
										<i class="fas fa-lg fa-info-circle" />
									</span>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="form-group">
									<input-text
										id="sponsor"
										:placeholder="translate('sponsor_information')"
										:show-label="false"
										:setter-value="sponsor.id"
										:errors="validationErrors['sponsor']"
										:required="true"
										type="text"
										autocomplete="sponsor"
										@dataChanged="sponsor.id = $event" />
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div
									class="h4 pb-0 mb-0">
									{{ translate('personal_information') }}
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-top-0 border-left-0 border-right-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.first_name`"
										:label="translate('first_name')"
										:setter-value="form.first_name"
										:errors="validationErrors[`${currentValidation}.first_name`]"
										:required="true"
										type="text"
										autocomplete="given-name"
										@dataChanged="form.first_name = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.last_name`"
										:label="translate('last_name')"
										:setter-value="form.last_name"
										:errors="validationErrors[`${currentValidation}.last_name`]"
										:required="true"
										type="text"
										autocomplete="family-name"
										@dataChanged="form.last_name = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.email`"
										:label="translate('email')"
										:setter-value="form.email"
										:errors="validationErrors[`${currentValidation}.email`]"
										:required="true"
										:max="maxEmailLength"
										type="text"
										autocomplete="email"
										@dataChanged="form.email = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="mobile_number">
										{{ translate('mobile_number') }}
										<span class="text-danger">*</span>
									</label>
									<vue-tel-input
										:id="`${currentValidation}.mobile_number`"
										ref="mobileNumberInput"
										v-model="form.mobile_number"
										:required="true"
										:label="translate('mobile_number')"
										:placeholder="translate('mobile_number')"
										:class="validationErrors[`${currentValidation}.mobile_number`] ? 'is-invalid' : ''"
										v-bind="bindProps"
										type="text"
										:name="`${currentValidation}.mobile_number`"
										class="form-control rounded-1"
										autocomplete="tel"
										@dataChanged="form.mobile_number = $event" />
									<template v-if="validationErrors[`${currentValidation}.mobile_number`]">
										<span
											v-for="error in validationErrors[`${currentValidation}.mobile_number`]"
											:key="error"
											class="invalid-feedback animated fadeIn"
											v-text="error" />
									</template>
								</div>
							</div>
						</div>
					</li>
					<!-- Always HIDE the following element when is a custom cart -->
					<li
						v-if="!forceCreateAccount"
						:class="!createAccount ? 'border-bottom-0' : ''"
						class="list-group-item border-left-0 border-right-0 rounded-0">
						<div class="row">
							<div class="col-12">
								<label class="form-check-label h5">
									{{ translate('create_an_account') }}
								</label>
								<div class="form-check">
									<input
										id="createAccount"
										v-model="createAccount"
										name="createAccount"
										class="form-check-input"
										type="checkbox">
									<label for="createAccount">
										{{ translate('create_an_account_detail') }}
										<label
											v-if="automaticDiscountPercentage > 0"
											class="text-primary small font-weight-bold">
											{{ translate('create_an_account_discount', { percentage: automaticDiscountPercentage }) }}
											<i class="fas fa-lg fa-tags" />
										</label>
									</label>
								</div>
							</div>
						</div>
					</li>
					<li
						v-if="shouldCreateAccount"
						class="list-group-item border-0 rounded-0 py-0">
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.username`"
										:label="translate('username')"
										:setter-value="form.username"
										:errors="validationErrors[`${currentValidation}.username`]"
										:required="true"
										type="text"
										autocomplete="off"
										@dataChanged="form.username = $event">
										<template slot="afterLabelInline">
											<span
												v-b-tooltip.hover.html.right
												:title="translate('username_used_for_replicated_site', { website: `${website}/${form.username || translate('username')}` })"
												class="text-muted small mx-2">
												<i class="fas fa-lg fa-info-circle" />
											</span>
										</template>
									</input-text>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.password`"
										:label="translate('password')"
										:setter-value="form.password"
										:errors="validationErrors[`${currentValidation}.password`]"
										:required="true"
										type="password"
										autocomplete="off"
										@dataChanged="form.password = $event" />
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.password_confirmation`"
										:label="translate('password_confirmation')"
										:setter-value="form.password_confirmation"
										:errors="validationErrors[`${currentValidation}.password_confirmation`]"
										:required="true"
										type="password"
										autocomplete="off"
										class="text-nowrap"
										@dataChanged="form.password_confirmation = $event" />
								</div>
							</div>
						</div>
					</li>
				</form>
				<li class="list-group-item border-0 rounded-0 pt-0 pb-3">
					<div class="row no-gutters justify-content-end">
						<div
							v-if="currentStep() !== 0"
							:class="{ 'pr-2': currentStep() !== 0 }"
							class="col">
							<b-button
								variant="secondary"
								size="lg"
								:style="!['xs'].includes(windowWidth) ? 'min-width: 150px;' : ''"
								:class="{ 'w-100': ['xs'].includes(windowWidth) }"
								class="float-md-right"
								@click="$emit('cancel')">
								{{ translate('cancel') }}
							</b-button>
						</div>
						<div
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							:class="currentStep() !== 0 ? 'col-6' : 'col-12'"
							class="col col-md-3">
							<b-button
								:disabled="validating || openPhoneVerificationModal || preparing || creatingIdentity"
								variant="primary"
								size="lg"
								:style="currentStep() !== 0 ? '' : 'min-width: 200px;'"
								class="w-100"
								@click="prepareForStorage()">
								<i
									v-if="validating || preparing || creatingIdentity"
									class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</li>
			</div>
		</div>
		<phone-verification-modal
			v-show="isPhoneVerificationRequired"
			:phone-number="form.mobile_number"
			:open.sync="openPhoneVerificationModal"
			recaptcha-action-name="checkout_verification"
			is-register
			@verified="handlePhoneVerified"
			@changeNumber="focusMobileNumberInput"
			@phoneValidationError="focusMobileNumberInput" />
		<!-- <custom-modal
			:show.sync="showAccountModal"
			:pre-title="translate('customer_register_pretitle')"
			:title="translate('customer_register_title')"
			:modal-size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-2'"
			:confirm-text="translate('customer_register_confirm')"
			:cancel-text="translate('customer_register_cancel')"
			ref-name="customerRegisterModal"
			@cancel="prepareForStorage()"
			@confirm="continueWithAccount()">
			<div
				class="text-center custom-modal-body px-3"
				v-html="translate('customer_register_body')" />
			<hr
				:class="{
					'mx-3': ['xs', 'sm'].includes(windowWidth),
					'mx-6': !['xs', 'sm'].includes(windowWidth),
				}"
				class="mt-3 mb-2">
			<div
				class="bg-gray-100 text-center pt-3 pb-2"
				v-html="translate('customer_register_body_badge')" />
			<hr
				:class="{
					'mx-3': ['xs', 'sm'].includes(windowWidth),
					'mx-6': !['xs', 'sm'].includes(windowWidth),
				}"
				class="mt-2 mb-3">
			<div
				:class="{
					'mx-1': ['xs', 'sm'].includes(windowWidth),
					'px-6': !['xs', 'sm'].includes(windowWidth),
				}"
				class="row custom-modal-labels px-3">
				<div class="col-12">
					<div class="form-group">
						<input-text
							:id="`${currentValidation}.username`"
							:label="translate('username')"
							:setter-value="form.username"
							:required="true"
							placeholder=" "
							type="text"
							@dataChanged="form.username = $event" />
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<div class="form-group">
						<input-text
							:id="`${currentValidation}.password`"
							:label="translate('password')"
							:setter-value="form.password"
							:required="true"
							type="password"
							placeholder=" "
							@dataChanged="form.password = $event" />
					</div>
				</div>
				<div class="col-12 col-xl-6">
					<div class="form-group">
						<input-text
							:id="`${currentValidation}.password_confirmation`"
							:label="translate('password_confirmation')"
							:setter-value="form.password_confirmation"
							:required="true"
							type="password"
							class="text-nowrap"
							placeholder=" "
							@dataChanged="form.password_confirmation = $event" />
					</div>
				</div>
			</div>
		</custom-modal> -->
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import PhoneVerificationModal from '@/components/PhoneVerificationModal';
import InputText from '@/components/InputText';
import PaymentProcessor from '@/util/PaymentProcessor';
import { PHONE_VERIFICATION_STATES, VERIFY_PHONE_COUNTRIES } from '@/settings/PhoneVerification';
import { MAX_EMAIL_LENGTH } from '@/settings/Validations';
// import CustomModal from '@/components/CustomModal';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE,
} from '@/settings/Errors';
import { BLACKLIST_UNSET_FIELDS } from '@/settings/Purchase';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from '../../mixins/Common';
import Steps from '../../mixins/Steps';
import PhoneVerification from '@/util/PhoneVerification';
import { ONLY_TUUN_COUNTRIES } from '@/settings/Register';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';

export default {
	name: 'PersonalInformationEdit',
	components: {
		// CustomModal,
		InputText,
		PhoneVerificationModal,
		VueTelInput,
	},
	mixins: [CommonMix, Steps, WindowSizes],
	props: {
		predefinedInformation: {
			type: Object,
			default: () => ({}),
		},
		forceCreateAccount: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			paymentProcessor: new PaymentProcessor(),
			phoneVerification: new PhoneVerification(),
			sponsor: {
				id: '',
				name: '',
			},
			showSponsor: true,
			identityId: null,
			uuid: null,
			maxEmailLength: MAX_EMAIL_LENGTH,
			blackListFields: BLACKLIST_UNSET_FIELDS,
			website: process.env.VUE_APP_WEBSITE,
			bindProps: {
				mode: 'international',
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			form: {
				first_name: '',
				last_name: '',
				email: '',
				mobile_number: '',
				username: '',
				password: '',
				password_confirmation: '',
			},
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			createAccount: false,
			verificationPhoneToken: '',
			mobileNumberVerified: '',
			openPhoneVerificationModal: false,
			preparing: false,
			currentVerificationState: PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED,
			showAccountModal: false,
			automaticDiscountPercentage: 0,
			ONLY_TUUN_COUNTRIES,
			availableCountries,
		};
	},
	computed: {
		path() {
			return this.shouldCreateAccount ? 'customer' : 'guest';
		},
		isPhoneVerificationRequired() {
			return this.currentVerificationState === PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED
				&& VERIFY_PHONE_COUNTRIES.includes(this.country)
				&& !this.isTheSameMobileNumber();
		},
		creatingIdentity() {
			return this.paymentProcessor.data.loading;
		},
		shouldCreateAccount() {
			return this.createAccount || this.forceCreateAccount;
		},
		isOrganizationVerified() {
			return this.phoneVerification.data.response.data.response.is_verified;
		},
	},
	watch: {
		createAccount(create) {
			if (!create) {
				this.form.username = '';
				this.form.password = '';
				this.form.password_confirmation = '';
				this.clearValidationError(`${this.currentValidation}.username`);
				this.clearValidationError(`${this.currentValidation}.password`);
				this.clearValidationError(`${this.currentValidation}.password_confirmation`);
			}
		},
		predefinedInformation() {
			this.form = { ...this.form, ...this.predefinedInformation };
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = this.availableCountries;
		this.getStoredInformation();
		this.initializePhoneVerification();
		const sponsorId = this.$replicated.replicatedSponsor();
		if (sponsorId !== null) {
			this.sponsor.id = String(sponsorId);
			this.showSponsor = false;
			// FIXME: The variable automaticDiscountPercentage must be 0 when a promotion is active
			// Get automatic customer discount if not replicated without discounts
			if (!ONLY_TUUN_COUNTRIES.includes(this.getStoredCountry())) {
				this.getSponsorInfo(this.sponsor.id).then((response) => {
					this.automaticDiscountPercentage = response.auto_apply_customer_coupons ?? 0;
				});
			}
		}

		this.trackPersonalInfoEditEvent();

		this.initializeStepNumber(0);
	},
	methods: {
		isTheSameMobileNumber() {
			return String(this.mobileNumberVerified).replaceAll(' ', '').localeCompare(String(this.form.mobile_number).replaceAll(' ', '')) === 0;
		},
		initializePhoneVerification() {
			this.verificationPhoneToken = '';

			if (VERIFY_PHONE_COUNTRIES.includes(this.country)) {
				this.currentVerificationState = PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED;
			} else {
				this.currentVerificationState = PHONE_VERIFICATION_STATES.VERIFICATION_NOT_REQUIRED;
			}
		},
		getStoredInformation() {
			const {
				sponsor,
				identity_id: identityId,
				uuid,
				create_account: createAccount,
				mobile_number_verified: mobileNumberVerified,
			} = this.getStepInformation('CheckoutPersonalInformation');
			const personalInfoSaved = { ...this.getStepInformation('CheckoutPersonalInformation')[this.currentValidation] };
			if (!Object.keys(personalInfoSaved).length) {
				return null;
			}
			this.createAccount = createAccount || this.forceCreateAccount;
			this.sponsor.id = sponsor;
			this.identityId = identityId;
			this.uuid = uuid;
			this.mobileNumberVerified = mobileNumberVerified ?? '';

			Object.keys(this.form).forEach((key) => {
				if (typeof personalInfoSaved[key] !== 'undefined' && !this.blackListFields.includes(key)) {
					this.form[key] = personalInfoSaved[key];
				}
			});
			return null;
		},
		prepareForStorage() {
			this.validateForm().then((payload) => {
				if (this.isPhoneVerificationRequired && !this.isOrganizationVerified) {
					this.openPhoneVerification();
				} else {
					if (this.isTheSameMobileNumber()) {
						const { verification_phone_token: verificationPhoneToken } = this.getStepInformation('CheckoutPersonalInformation');
						payload.verification_phone_token = verificationPhoneToken;
					}
					this.saveToStorage(payload);
				}
			})
				.catch((error) => this.handleValidateFormErrors(error))
				.finally(() => { this.preparing = false; });
		},
		continueWithAccount() {
			this.createAccount = true;
			this.prepareForStorage();
		},
		async validateForm() {
			await this.phoneVerification.isOrganizationVerified(this.sponsor.id);

			return new Promise((resolve, reject) => {
				const payload = this.buildStepValidationPayload();

				this.validateStep(payload)
					.then(() => resolve(payload))
					.catch((error) => reject(error));
			});
		},
		validateShowModal() {
			this.validateForm().then(() => {
				this.showAccountModal = true;
			}).catch((error) => this.handleValidateFormErrors(error));
		},
		handleValidateFormErrors(error) {
			if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
				this.$emit('invalidRequest', error);
			}
			if (UNPROCESSABLE.includes(error.status)) {
				const { sponsor, cart_id: cartId } = error.errors;
				if (typeof cartId !== 'undefined') {
					let response = '';
					cartId.forEach((item) => { response += `${item} \n`; });
					this.alert.toast('error', response, { timer: 6000 });
					setTimeout(() => {
						this.$emit('cartValidationError');
					}, 6000);
				}
				// Redirect to the store if the sponsor was selected via the replicated site and they get a validation error
				if (!this.showSponsor && typeof sponsor !== 'undefined') {
					this.$emit('cantUseSelectedSponsor');
				}
			}
		},
		saveToStorage(payload) {
			this.createIdentity().then(() => {
				payload.identity_id = this.identityId;
				payload.uuid = this.uuid;
				this.saveStep(payload);
			}).catch(() => {
				this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
			});
		},
		handlePhoneVerified(token) {
			this.verificationPhoneToken = token;
			this.mobileNumberVerified = this.form.mobile_number ?? '';
			this.saveToStorage(this.buildStepValidationPayload());
		},
		buildStepValidationPayload() {
			let form = { ...this.form };
			if (this.path === 'guest') {
				form = {
					first_name: this.form.first_name,
					last_name: this.form.last_name,
					email: this.form.email,
					mobile_number: this.form.mobile_number,
				};
			}

			const payload = {
				step: this.currentValidation,
				sponsor: this.sponsor.id,
				personal_information: form,
				identity_id: this.identityId,
				uuid: this.uuid,
				path: this.path,
				create_account: this.shouldCreateAccount,
			};

			if (this.verificationPhoneToken) {
				payload.verification_phone_token = this.verificationPhoneToken;
			}

			if (this.mobileNumberVerified) {
				payload.mobile_number_verified = this.mobileNumberVerified ?? '';
			}

			return payload;
		},
		createIdentity() {
			const payload = {
				first_name: this.form.first_name,
				last_name: this.form.last_name,
				email: this.form.email,
				mobile_number: this.form.mobile_number,
				identity_id: this.identityId,
				uuid: this.uuid,
				verification_phone_token: this.verificationPhoneToken,
			};
			return this.paymentProcessor.createIdentity(payload).then((response) => {
				this.identityId = response.response.identity_id;
				this.uuid = response.response.uuid;
				return response;
			});
		},
		openPhoneVerification() {
			this.$emit('openedPhoneVerification');
			this.openPhoneVerificationModal = true;
		},
		focusMobileNumberInput() {
			this.$nextTick(() => {
				this.$refs.mobileNumberInput.focus();
			});
		},
		trackPersonalInfoEditEvent() {
			setTimeout(() => { // This is to prevent the event being fired when finishing the checkout (the steps are reset, which momentarily loads this component)
				if (this.$route.fullPath.includes('confirmation')) {
					this.$emit('opened'); // This is under the assumption that the component is shown with a v-if
				}
			}, 1000);
		},
	},
};
</script>
<style>
.mx-6 {
	margin-left: 6rem !important;
	margin-right: 6rem !important;
}

.px-6 {
	margin-left: 6rem !important;
	margin-right: 6rem !important;
}

.custom-modal-labels {
	font-size: 13px;
	font-family: 'Monserrat', sans-serif;
	font-weight: lighter;
}

.custom-modal-body {
	font-family: 'Monserrat', sans-serif;
	line-height: 1.6rem;
	font-size: medium;
}
</style>
