<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			v-show="paymentMethodEnabled"
			v-if="canSplitPayment"
			class="row">
			<div class="col-12">
				<switch-toggle
					id="split_payment"
					v-model="splitPayment"
					name="split_payment"
					variant="success"
					class="mr-2"
					pill />
				<label for="split_payment">
					{{ translate('split_payment') }}
				</label>
				<hr class="p-1 m-1">
			</div>
		</div>
		<b-row v-if="showTitle">
			<b-col>
				<div class="h4 my-2">
					{{ translate('cc_information') }}
				</div>
			</b-col>
		</b-row>
		<div class="row mx-2 mb-3">
			<div
				v-show="paymentMethodEnabled"
				:class="['xs'].includes(windowWidth) ? 'p-0' : ''"
				class="col-12">
				<template v-for="(item, index) in displayedAllowedCC">
					<img
						:key="index"
						:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
						alt="Credit Card"
						align="right"
						class="text-right mr-2 resize-img">
				</template>
			</div>
		</div>
		<div
			v-show="!splitPayment"
			class="row">
			<div class="col">
				<div class="form-group mb-0">
					<form
						v-show="paymentMethodEnabled"
						class="w-100">
						<div class="form-group">
							<label for="cc-v2-name">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
							<span
								id="cc-v2-name"
								:class="lacoreV2CreditCardHasErrors('name') ? 'is-invalid' : ''"
								class="form-field form-control" />
							<template v-if="lacoreV2CreditCardHasErrors('name')">
								<p
									v-for="error in lacoreV2CreditCardErrors.name.errorMessages"
									:key="error"
									class="custom-invalid-feedback animated fadeIn text-danger mb-0"
									v-text="error" />
							</template>
						</div>
						<div class="form-group">
							<label for="cc-v2-number">{{ translate('card_number') }} <span class="text-danger">*</span></label>
							<span
								id="cc-v2-number"
								:class="lacoreV2CreditCardHasErrors('number') ? 'is-invalid' : ''"
								class="form-field form-control" />
							<template v-if="lacoreV2CreditCardHasErrors('number')">
								<p
									v-for="error in lacoreV2CreditCardErrors.number.errorMessages"
									:key="error"
									class="custom-invalid-feedback animated fadeIn text-danger mb-0"
									v-text="error" />
							</template>
						</div>
						<div class="row">
							<div class="form-group col-12 col-md-4">
								<label for="cc-v2-expiration-month">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
								<span
									id="cc-v2-expiration-month"
									:class="lacoreV2CreditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
									class="form-field form-control" />
								<template v-if="lacoreV2CreditCardHasErrors('expiration_month')">
									<p
										v-for="error in lacoreV2CreditCardErrors.expiration_month.errorMessages"
										:key="error"
										class="custom-invalid-feedback animated fadeIn text-danger mb-0"
										v-text="error" />
								</template>
							</div>
							<div class="form-group col-12 col-md-4">
								<label for="cc-v2-expiration-year">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
								<span
									id="cc-v2-expiration-year"
									:class="lacoreV2CreditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
									class="form-field form-control" />
								<template v-if="lacoreV2CreditCardHasErrors('expiration_year')">
									<p
										v-for="error in lacoreV2CreditCardErrors.expiration_year.errorMessages"
										:key="error"
										class="custom-invalid-feedback animated fadeIn text-danger mb-0"
										v-text="error" />
								</template>
							</div>
							<div class="form-group col-12 col-md-4">
								<label for="cc-v2-cvc">{{ translate('cvv') }} <span class="text-danger">*</span></label>
								<span
									id="cc-v2-cvc"
									:class="lacoreV2CreditCardHasErrors('security_code') ? 'is-invalid' : ''"
									class="form-field form-control" />
								<template v-if="lacoreV2CreditCardHasErrors('security_code')">
									<p
										v-for="error in lacoreV2CreditCardErrors.security_code.errorMessages"
										:key="error"
										class="custom-invalid-feedback animated fadeIn text-danger mb-0"
										v-text="error" />
								</template>
							</div>
						</div>
					</form>
					<b-alert
						:show="!paymentMethodEnabled"
						variant="danger"
						v-html="translate('creditcard_unavailable', {
							url: $router.resolve({ name: redirectTo }).href,
							max: typeof paymentMethodConditions.max !== 'undefined' ? paymentMethodConditions.maxFormatted : '',
							min: typeof paymentMethodConditions.min !== 'undefined' ? paymentMethodConditions.minFormatted : '',
						})" />
				</div>
			</div>
		</div>
		<div
			v-show="splitPayment"
			class="row no-gutters mb-3">
			<div class="col">
				<split-payment-credit-card-form-v2
					:split-payment="splitPayment"
					:form.sync="form"
					:split-amount.sync="splitPaymentAmount"
					:cart-total="cartTotal"
					:amount-errors="amountErrors"
					:credit-card1-errors="lacoreV2CreditCardErrors"
					:credit-card2-errors="lacoreV2CreditCard2Errors"
					@setupStart="$emit('preparing', true)"
					@setupEnd="$emit('preparing', false); splitCreditCardPaymentCallbacks = $event" />
			</div>
		</div>
		<div
			v-if="splitPayment"
			class="row no-gutters mb-3">
			<div class="col">
				<b-alert
					show
					class="mb-0"
					variant="warning">
					<div class="custom-control custom-checkbox my-auto">
						<input
							id="discount"
							v-model="splitPaymentAgreement"
							type="checkbox"
							class="custom-control-input">
						<label
							for="discount"
							class="custom-control-label">
							{{ translate('split_payment_agreement') }}
						</label>
					</div>
				</b-alert>
			</div>
		</div>
	</div>
</template>
<script>
import SwitchToggle from '@/components/Switch/index.vue';
import SplitPaymentCreditCardFormV2 from '@/components/SplitPaymentCreditCardFormV2/index.vue';
import WindowSizes from '@/mixins/WindowSizes';
import CreditCardFormV2 from '@/mixins/CreditCardFormV2';
import { ALLOWEDCC_BY_COUNTRY } from '@/settings/CreditCard';
import { PaymentMethods, Profile, PurchasePayment } from '@/translations';

export default {
	name: 'CreditCardPaymentRegisterV2',
	components: {
		SplitPaymentCreditCardFormV2,
		SwitchToggle,
	},
	messages: [PaymentMethods, PurchasePayment, Profile],
	mixins: [CreditCardFormV2, WindowSizes],
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		canSplitPayment: {
			type: Boolean,
			default: false,
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
		form: {
			type: Object,
			required: true,
		},
		redirectTo: {
			type: String,
			default: 'Store',
		},
		splitPaymentImport: {
			type: Boolean,
			default: false,
		},
		splitPaymentAmountImport: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {};
	},
	computed: {
		splitPaymentAgreementState() {
			if (this.splitPayment) {
				return this.splitPaymentAgreement;
			}
			return true;
		},
		paymentMethodEnabled() {
			return this.paymentMethodConditions.isAvailable;
		},
		showSplitPaymentMessages() {
			return this.splitPayment;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
	},
	watch: {
		canSplitPayment(canSplitPayment) {
			if (!canSplitPayment) {
				this.splitPayment = false;
				this.splitPaymentAgreement = false;
			}
		},
		splitPaymentImport: {
			handler() {
				this.splitPayment = this.splitPaymentImport;
			},
			immediate: true,
		},
		splitPaymentAmountImport: {
			handler() {
				this.splitPaymentAmount = this.splitPaymentAmountImport;
			},
			immediate: true,
		},
		splitPayment(splitPayment) {
			this.$emit('update:splitPayment', splitPayment);
		},
		splitPaymentAgreement(splitPaymentAgreement) {
			this.$emit('update:splitPaymentAgreement', splitPaymentAgreement);
		},
	},
	mounted() {
		this.lacoreV2FormSetup();
	},
};
</script>
