import { VERIFY_PHONE, VERIFY_PHONE_CODE, VERIFY_PHONE_ORGANIZATION } from '@/config/endpoint';
import Req from './AxiosRequest';

class PhoneVerification {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	verifyPhone(payload) {
		const { method, endpoint } = VERIFY_PHONE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	verifyPhoneCode(payload) {
		const { method, endpoint } = VERIFY_PHONE_CODE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	isOrganizationVerified(sponsorId) {
		const { method, endpoint } = VERIFY_PHONE_ORGANIZATION;
		return this.data[method](endpoint(sponsorId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default PhoneVerification;
