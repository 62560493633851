<template>
	<div class="mb-2">
		<div
			v-if="!!backofficeAccess.qty"
			class="row mb-2">
			<div class="col">
				<span
					:class="compact ? '' : 'font-weight-bold'"
					class="m-0">
					{{ backofficeAccess.qty }}
				</span>
				x
				<span
					:class="compact ? '' : 'font-weight-bold'"
					class="m-0">
					{{ translate(backofficeAccess.code_name) }}
				</span>
				<span
					v-if="backofficeAccess.sku.startsWith(freeProductPrefix)"
					class="font-weight-bold">
					-
					{{ translate('free_item') }}
				</span>
			</div>
		</div>
		<div
			v-if="!!backofficeFees.qty"
			class="row mb-2">
			<div class="col">
				<span
					:class="compact ? '' : 'font-weight-bold'"
					class="m-0">
					{{ backofficeFees.qty }}
				</span>
				x
				<span
					:class="compact ? '' : 'font-weight-bold'"
					class="m-0">
					{{ translate(backofficeFees.code_name) }}
				</span>
				<span
					v-if="backofficeFees.sku.startsWith(freeProductPrefix)"
					class="font-weight-bold">
					-
					{{ translate('free_item') }}
				</span>
			</div>
		</div>
		<div
			v-for="(childProducts, parentCodeName) in configurableProducts"
			:key="parentCodeName"
			class="row mb-2">
			<div class="col">
				<div class="h6 font-weight-bold mb-0">
					{{ translate(parentCodeName) }}
				</div>
				<div
					v-for="(childProduct, codeName) in childProducts"
					:key="codeName"
					:class="compact ? 'pl-1' : 'ml-1'"
					class="row">
					<div class="col">
						<span
							:class="compact ? '' : 'font-weight-bold'"
							class="m-0">
							{{ childProduct.qty }}
						</span>
						x
						<span
							:class="compact ? '' : 'font-weight-bold'"
							class="m-0">
							{{ getOnlyConfigurationName(translate(codeName)) }}
						</span>
						<span
							v-if="childProduct.sku.startsWith(freeProductPrefix)"
							class="font-weight-bold">
							-
							{{ translate('free_item') }}
						</span>
					</div>
				</div>
			</div>
		</div>
		<div
			v-for="(childProduct, codeName) in simpleProducts"
			:key="codeName"
			class="row">
			<div class="col">
				<span
					:class="compact ? '' : 'font-weight-bold'"
					class="m-0">
					{{ childProduct.qty }}
				</span>
				x
				<span
					:class="compact ? '' : 'font-weight-bold'"
					class="m-0">
					{{ translate(codeName) }}
				</span>
				<span
					v-if="childProduct.sku.startsWith(freeProductPrefix)"
					class="font-weight-bold">
					-
					{{ translate('free_item') }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import {
	BACKOFFICE_ACCESS_SKU, FREEPRODUCTPREFIX, SIMPLE_PRODUCTS_PARENT_PLACEHOLDER, BACKOFFICE_UPGRADE_FEES,
} from '@/settings/Products';
import { Products, Purchase } from '@/translations';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';

// Example products prop data structure:
// 	{
// 		[parent A code name]: {
// 			[child A1 code name]: {
// 				qty: [child A1 qty],
// 				sku: [child A1 sku],
// 			},
// 			[child B1 code name]: {
// 				qty: [child B1 qty],
// 				sku: [child B1 sku],
// 			},
// 			...
// 		},
// 		[parent B code name]: {
// 			...
// 		},
// 		...
// 		[SIMPLE_PRODUCTS_PARENT_PLACEHOLDER]: {
// 			[simple product 1 code name]: {
// 				qty: [simple product 1 qty],
// 				sku: [simple product 1 qty],
// 			},
// 		},
// 	}

export default {
	name: 'SelectedProductsOverview',
	messages: [Products, Purchase],
	mixins: [ConfigurableProducts],
	props: {
		products: {
			type: Object,
			default: () => ({}),
		},
		compact: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			freeProductPrefix: FREEPRODUCTPREFIX,
		};
	},
	computed: {
		backofficeAccess() {
			const [backofficeAccessEntry = []] = this.simpleProductEntries.filter(([, product]) => product.sku.includes(BACKOFFICE_ACCESS_SKU));
			const [codeName = null, backofficeAccess = {}] = backofficeAccessEntry;

			return { code_name: codeName, ...(backofficeAccess || {}) };
		},
		backofficeFees() {
			const [backofficeAccessEntry = []] = this.simpleProductEntries.filter(([, product]) => product.sku.includes(BACKOFFICE_UPGRADE_FEES));
			const [codeName = null, backofficeAccess = {}] = backofficeAccessEntry;

			return { code_name: codeName, ...(backofficeAccess || {}) };
		},
		configurableProducts() {
			return (({ none, ...others }) => others)(this.products);
		},
		simpleProducts() {
			const simpleProducts = this.simpleProductEntries.filter(([, product]) => !product.sku.includes(BACKOFFICE_ACCESS_SKU) && !product.sku.includes(BACKOFFICE_UPGRADE_FEES));

			return Object.fromEntries(simpleProducts);
		},
		simpleProductEntries() {
			return Object.entries(this.products[SIMPLE_PRODUCTS_PARENT_PLACEHOLDER] || {});
		},
	},
	methods: {
		isBackoffice(product) {
			return product.sku.includes(BACKOFFICE_ACCESS_SKU);
		},
	},
};
</script>
