import PaymentProcessor from '@/util/PaymentProcessor';

export default {
	data() {
		return {
			alert: new this.$Alert(),
			paymentProcessor: new PaymentProcessor(),
		};
	},
	methods: {
		lacoreV2CreateIdentity() {
			const personal = this.getStepInformation('CheckoutPersonalInformation');
			const {
				personal_information: personalInformation,
				uuid,
			} = personal;
			const { shipping } = this.getStepInformation('CheckoutShipping');

			// Save Identity ID
			const identityData = {
				first_name: personalInformation.first_name,
				last_name: personalInformation.last_name,
				email: personalInformation.email,
				mobile_number: personalInformation.mobile_number,
				identity_id: personalInformation.identityId,
				uuid,
				personal_address: shipping.shipping_address,
			};
			return this.paymentProcessor.createIdentityV2(identityData).then((response) => {
				this.identityId = response.response.identity_id;
				this.uuid = response.response.uuid;
				const payload = {
					...personal,
					identity_id: this.identityId,
					uuid: this.uuid,
				};
				this.saveStep(payload, false, false);
			});
		},
	},
};
