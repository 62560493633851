<template>
	<b-modal
		ref="customCartProductsModal"
		hide-footer
		header-class="mx-auto w-100 bg-dark text-white"
		:size="smallSize ? 'md' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="close">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate(reviewCart ? 'review_your_cart_title' : 'cart_products') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close">
				×
			</button>
		</template>
		<div v-if="reviewCart">
			<div class="col col-auto text-center">
				<span><b> {{ translate('review_your_cart_subtitle') }} </b></span>
			</div>
			<div class="col col-auto text-center pb-3">
				<span> {{ translate('review_your_cart_description') }} </span>
			</div>
		</div>
		<div
			v-if="!loading"
			class="row no-gutters justify-content-center">
			<div
				class="pb-2"
				:class="!smallSize ? 'col-6' : 'col-12'">
				<div class="col col-auto text-center">
					<span><b> {{ translate('sponsor_products') }} </b></span>
				</div>
				<hr class="m-3">
				<div
					class="border-secondary mb-2 px-2 pt-2 pb-0 card-products"
					style="border: 1px solid black; border-radius: 5px"
					:style="{
						height: smallSize && cartChanged ? '' : '335px',
						maxHeight: smallSize && cartChanged ? '120px' : '335px',
					}">
					<div class="row mt-1 mb-1">
						<div
							v-if="!loading"
							class="col-12">
							<div
								v-for="(product, i) in boProducts"
								:key="i">
								<div
									class="row pb-2 pl-2 pt-1">
									<div class="col-12">
										<img
											:src="product.image"
											width="50px"
											class="img-fluid"
											alt="Product Image">
										<span
											class="pl-1">
											<strong> {{ product.qty }} x </strong> {{ product.name }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="cartChanged"
				class="pb-2"
				:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'col-12' : 'col-6'">
				<div class="col col-auto text-center">
					<span><b> {{ translate('customer_products') }} </b></span>
				</div>
				<hr class="m-3">
				<div
					class="border-secondary mb-2 px-2 pt-2 pb-0 card-products"
					style="border: 1px solid black; border-radius: 5px"
					:style="{
						height: smallSize ? '' : '335px',
						maxHeight: smallSize ? '120px' : '335px',
					}">
					<div class="row mt-1 mb-1">
						<div
							v-if="!loading"
							class="col-12">
							<div
								v-for="(product, i) in storeProducts"
								:key="i">
								<div
									class="row pb-2 pl-2 pt-1">
									<div class="col-12">
										<img
											:src="product.image"
											width="50px"
											class="img-fluid"
											alt="Product Image">
										<span
											class="pl-1">
											<strong> {{ product.qty }} x </strong> {{ product.name }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="reviewCart"
			class="row justify-content-end">
			<div class="col-12 col-lg-4 pb-1">
				<button
					type="button"
					class="btn btn-secondary btn-block"
					:disabled="loading"
					@click="$router.push({ name: 'Store' }).catch(() => {})">
					{{ translate('edit_cart') }}
				</button>
			</div>
			<div class="col-12 col-lg-4 pb-1">
				<button
					type="button"
					class="btn bg-primary-alt btn-block"
					:disabled="loading"
					@click="$emit('restore')">
					{{ translate('restore_cart') }}
				</button>
			</div>
			<div class="col-12 col-lg-4 pb-1">
				<button
					type="button"
					class="btn btn-primary btn-block"
					:disabled="loading"
					@click="$emit('confirm', true)">
					{{ translate('confirm_and_pay') }}
				</button>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</b-modal>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { CustomCarts as customCartsMessages } from '@/translations';
import EventBus from '@/util/eventBus';
import IsLoading from '@/components/Loading';

export default {
	name: 'CustomCartProductsModal',
	messages: [customCartsMessages],
	components: {
		IsLoading,
	},
	mixins: [WindowSizes],
	props: {
		cartProducts: {
			type: Object,
			default: () => ({}),
		},
		cartId: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		reviewCart: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		boProducts() {
			return this.cartProducts.bo_products;
		},
		storeProducts() {
			return this.cartProducts.store_products;
		},
		cartChanged() {
			return this.cartProducts.cart_changed;
		},
		smallSize() {
			return !this.cartChanged
				|| ['xs', 'sm', 'md'].includes(this.windowWidth);
		},
	},
	mounted() {
		EventBus.$on('showCustomCartProductsModal', () => {
			this.open();
		});
		EventBus.$on('hideCustomCartProductsModal', () => {
			this.close();
		});
	},
	beforeDestroy() {
		EventBus.$off('showCustomCartProductsModal');
		EventBus.$off('hideCustomCartProductsModal');
	},
	methods: {
		open() {
			this.$refs.customCartProductsModal.show();
		},
		close() {
			this.$refs.customCartProductsModal.hide();
			this.$emit('close');
		},
	},
};
</script>
<style>
/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
.modal-lg-custom {
	max-width: 880px !important;
	width: 880px !important;
}
.card-products {
	overflow-y: scroll;
	overflow-x: hidden;
}
</style>
