<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="autoshipModal"
		hide-footer
		no-close-on-esc
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="cancel">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ title }}
			</h5>
		</template>
		<div
			v-if="!loading"
			class="row no-gutters justify-content-center">
			<div
				v-if="!!description"
				class="col-12">
				<div class="my-2">
					<b-alert
						variant="info"
						class="m-0"
						show
						v-html="description" />
				</div>
			</div>
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div class="row mx-2 mt-1">
					<div class="col-12">
						<div
							v-for="(item, key) in configurableProducts"
							:key="item.attributes.sku"
							:class="key < configurableProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''"
							class="row">
							<div class="col">
								<configurable-product
									:code-name="item.attributes.code_name"
									:sku="item.attributes.sku"
									:config-name="getProductConfigKey(item)"
									:thumbnail="item.attributes.thumbnail"
									:stacked-layout="['xs', 'sm'].includes(windowWidth)">
									<div class="row">
										<div
											v-for="(config, configKey) in getProductConfigs(item)"
											:key="configKey"
											class="col-12 col-md-6">
											<product-configuration
												:configuration="getConfiguredProduct(item, configKey).code_name"
												:init-qty="selectedProducts[getConfiguredProduct(item, configKey).sku].value || 0"
												:stacked-layout="['xs', 'sm'].includes(windowWidth)"
												@change="$set(selectedProducts[getConfiguredProduct(item, configKey).sku], 'value', $event)" />
										</div>
									</div>
								</configurable-product>
							</div>
						</div>
					</div>
					<div class="col-12 border border-secondary border-bottom-0 border-left-0 border-right-0 pt-2 mt-1">
						<div class="row mb-3">
							<div
								:class="['xs', 'sm'].includes(windowWidth) ? 'pl-0' : ''"
								class="col-12">
								<div class="row">
									<div
										v-for="item in simpleProducts"
										:key="item.attributes.sku"
										class="col-12 col-md-6">
										<simple-product
											:code-name="item.attributes.code_name"
											:bvs="Number(item.attributes.bvs)"
											:init-qty="selectedProducts[item.attributes.sku].value || 0"
											:thumbnail="item.attributes.thumbnail"
											:stacked-layout="['xs', 'sm'].includes(windowWidth)"
											@change="$set(selectedProducts[item.attributes.sku], 'value', $event)" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col col-12 no-gutters justify-content-end text-right">
				<button
					type="button"
					:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
					aria-label="Close"
					class="btn w-100 btn-secondary btn-lg mt-3 mr-2"
					@click="cancel()">
					{{ translate('cancel') }}
				</button>
				<button
					:disabled="disableConfirm"
					type="button"
					:style="['xs'].includes(windowWidth) ? '' : 'max-width: 200px;'"
					aria-label="Close"
					class="btn w-100 btn-primary btn-lg mt-3"
					@click="confirm()">
					{{ translate('confirm_autoship') }}
				</button>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!products.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</b-modal>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase } from '@/translations';
import ConfigurableProduct from './ConfigurableProduct';
import ProductConfiguration from './ProductConfiguration';
import SimpleProduct from './SimpleProduct';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';

export default {
	name: 'AddAutoshipModal',
	messages: [Products, Purchase],
	components: {
		ConfigurableProduct,
		IsLoading,
		ProductConfiguration,
		SimpleProduct,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		autoshipProducts: {
			type: Object,
			default: () => ({}),
		},
		loadingProducts: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectedProducts: {},
		};
	},
	computed: {
		loading() {
			return this.loadingProducts || !Object.keys(this.selectedProducts).length || !this.products.length;
		},
		simpleProducts() {
			return this.products.filter((product) => !product.attributes.has_configurations);
		},
		configurableProducts() {
			return this.products.filter((product) => product.attributes.has_configurations);
		},
		disableConfirm() {
			const selectedQty = Object.values(this.selectedProducts).reduce((accum, product) => accum + product.value, 0);
			const minQty = AUTOSHIP_MIN_QTY[this.country];
			return selectedQty < minQty;
		},
	},
	watch: {
		open(newVal) {
			if (newVal) {
				this.$refs.autoshipModal.show();
				this.selectedProducts = window.structuredClone(this.autoshipProducts);
			} else this.$refs.autoshipModal.hide();
		},
	},
	mounted() {
		if (this.open) this.$refs.autoshipModal.show();
	},
	methods: {
		confirm() {
			this.$emit('confirm', this.selectedProducts);
		},
		cancel() {
			this.$emit('cancel');
		},
	},
};
</script>
<style>
	/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
	.modal-lg-custom {
		max-width: 880px !important;
		width: 880px !important;
	}
</style>
