export const OLD_GEN_COUNTRIES = [];
export const NEW_GEN_CONFIGURATIONS = [
	{ value: null, code_name: 'max', weeks: null },
	{ value: 'monthly', code_name: 'monthly', weeks: 4 },
	{ value: 'every_two_months', code_name: 'every_two_months', weeks: 8 },
	{ value: 'every_three_months', code_name: 'every_three_months', weeks: 12 },
	{ value: 'every_four_months', code_name: 'every_four_months', weeks: 16 },
	{ value: 'every_five_months', code_name: 'every_five_months', weeks: 20 },
];

export const NO_AUTOSHIP_COUNTRIES = ['BD', 'BR', 'CL', 'CD', 'EC', 'GH', 'IN', 'ID', 'KE', 'MQ', 'MU', 'MA', 'NE', 'NG', 'PE', 'ZA', 'TW', 'TR', 'UG', 'UY', 'ZM', 'PH', 'CR', 'GT', 'SV', 'AR'];

export const CUSTOMER_LOYALTY_DISCOUNTS = [
	'discount2', // 15%
	'discount3', // 20%
	'discount4', // 25%
];

export default {};
