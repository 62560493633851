<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="phoneVerificationModal"
		hide-footer
		no-close-on-esc
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'md'"
		:body-class="['xs'].includes(windowWidth) ? 'p-3' : ''"
		centered
		@hide="hide">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate('phone_verification') }}
			</h5>
			<button
				v-if="!loadingVerification && currentVerificationStep === VERIFY_PHONE_NUMBER"
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="hide">
				×
			</button>
		</template>
		<div>
			<template v-if="!loadingVerification">
				<form
					@submit.prevent="submitVerification"
					@keydown="clearError($event.target.name);">
					<p v-if="currentVerificationStep === VERIFY_PHONE_NUMBER">
						{{ translate('verify_phone_number_description') }}
					</p>
					<div
						v-if="currentVerificationStep === VERIFY_PHONE_NUMBER"
						class="row justify-content-center py-2">
						<div class="col-auto">
							<span class="h3">
								{{ phoneNumber }}
							</span>
						</div>
					</div>
					<template v-if="errors.errors['mobile_number']">
						<span
							v-for="error in errors.errors['mobile_number']"
							:key="error"
							class="invalid-feedback animated fadeIn"
							v-text="error" />
					</template>
					<label
						v-if="currentVerificationStep === VERIFY_PHONE_CODE"
						for="verification_code"
						class="text-medium">
						{{ translate('verification_code_sent', { number: maskedPhoneNumber }) }}
					</label>
					<input-text
						v-if="currentVerificationStep === VERIFY_PHONE_CODE"
						id="verification_code"
						:label="translate('verification_code')"
						:setter-value="verificationCode"
						:errors="errors.errors['verification_code']"
						:required="true"
						name="verification_code"
						type="text"
						@dataChanged="verificationCode = $event" />
					<div class="row no-gutters justify-content-end mt-3">
						<div
							v-if="currentVerificationStep === VERIFY_PHONE_CODE"
							:class="{
								'pr-3': !['xs', 'sm'].includes(windowWidth),
								'justify-content-center mb-1': ['xs', 'sm'].includes(windowWidth),
							}"
							class="col order-3 order-md-1 float-left d-flex align-items-center">
							<a
								v-if="canAttemptResend"
								class="pointer text-blue"
								@click.prevent="reset">
								{{ translate('resend_code') }}
							</a>
							<span
								v-else
								class="text-muted">
								{{ translate('resend_code') }} ({{ cooldown }})
							</span>
						</div>
						<div
							:class="{
								'pr-3': !['xs', 'sm'].includes(windowWidth),
								'justify-content-center mb-1': ['xs', 'sm'].includes(windowWidth),
							}"
							class="col-12 col-md-auto order-2 order-md-2 d-flex align-items-center">
							<a
								class="pointer text-blue"
								@click.prevent="changeNumber">
								{{ translate('change_number') }}
							</a>
						</div>
						<div
							:class="{
								'pl-2': !['xs', 'sm'].includes(windowWidth),
							}"
							class="col col-md-auto order-1 order-md-3">
							<button
								:disabled="Object.keys(errors.errors).length > 0"
								type="submit"
								:class="{ 'w-100': ['xs', 'sm'].includes(windowWidth) }"
								class="btn btn-primary">
								{{ currentVerificationStep === VERIFY_PHONE_NUMBER ? translate('send_code') : translate('verify') }}
							</button>
							<hr v-if="['xs', 'sm'].includes(windowWidth)">
						</div>
					</div>
				</form>
			</template>
			<is-loading
				v-else
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loadingVerification"
				:has-data="true"
				class="mt-3" />
		</div>
	</b-modal>
</template>
<script>
import InputText from '@/components/InputText';
import IsLoading from '@/components/Loading';
import Captcha from '@/mixins/Captcha';
import WindowSizes from '@/mixins/WindowSizes';
import {
	PHONE_VERIFICATION_STEPS as STEPS,
	PHONE_VERIFICATION_RESPONSE_STATUSES as VERIFICATION_STATUSES,
	RESEND_CODE_COOLDOWN,
} from '@/settings/PhoneVerification';
import { UNPROCESSABLE } from '@/settings/Errors';
import PhoneVerification from '@/util/PhoneVerification';
import { PhoneVerification as PhoneVerificationMessages } from '@/translations';

export default {
	name: 'PhoneVerificationModal',
	messages: [PhoneVerificationMessages],
	components: {
		InputText,
		IsLoading,
	},
	mixins: [Captcha, WindowSizes],
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		phoneNumber: {
			type: String,
			default: '',
		},
		recaptchaActionName: {
			type: String,
			required: true,
		},
		isRegister: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			verificationCode: '',
			phoneVerification: new PhoneVerification(),
			currentVerificationStep: STEPS.VERIFY_PHONE_NUMBER,
			cooldown: 0, // seconds
			...STEPS,
		};
	},
	computed: {
		errors() {
			return this.phoneVerification.data.errors;
		},
		loadingVerification() {
			return this.phoneVerification.data.loading;
		},
		maskedPhoneNumber() {
			return this.maskPhoneNumber(this.phoneNumber);
		},
		canAttemptResend() {
			return !this.cooldown;
		},
	},
	watch: {
		open: {
			handler(newVal) {
				this.handleOpen(newVal);
			},
		},
		cooldown: {
			handler() {
				if (this.cooldown > 0) {
					setTimeout(() => {
						this.cooldown -= 1;
					}, 1000);
				}
			},
			immediate: true,
		},
	},
	mounted() {
		this.handleOpen(this.open);
		this.reset();
	},
	methods: {
		reset() {
			this.verificationCode = '';
			this.clearError('mobile_number');
			this.clearError('verification_code');
			this.currentVerificationStep = STEPS.VERIFY_PHONE_NUMBER;
		},
		changeNumber() {
			this.$emit('changeNumber');
			this.hide();
		},
		submitVerification() {
			this.getCaptchaToken(this.recaptchaActionName).then((response) => {
				const payload = {
					mobile_number: this.phoneNumber,
					'g-recaptcha-response': response,
					is_register: this.isRegister,
				};

				if (this.currentVerificationStep === STEPS.VERIFY_PHONE_NUMBER) {
					this.phoneVerification.verifyPhone(payload).then((verificationResponse) => {
						this.handleVerificationStatus(verificationResponse);
					}).catch(() => {
						this.handleVerificationError();
					});
				} else if (this.currentVerificationStep === STEPS.VERIFY_PHONE_CODE) {
					this.phoneVerification.verifyPhoneCode({
						...payload,
						verification_code: this.verificationCode,
					}).then((verificationResponse) => {
						this.handleSuccessfulVerification(verificationResponse);
					}).catch(() => {
						this.handleVerificationError();
					});
				}
			});
		},
		handleVerificationStatus(response) {
			const { status } = response.response;

			if (status === VERIFICATION_STATUSES.SENT) {
				this.handleSentStatus();
			} else if (status === VERIFICATION_STATUSES.WHITELISTED) {
				this.handleSuccessfulVerification(response);
			}
		},
		handleSentStatus() {
			this.alert.toast('success', this.translate('sms_send_success'));
			this.currentVerificationStep = STEPS.VERIFY_PHONE_CODE;
			this.cooldown = RESEND_CODE_COOLDOWN; // start resend cooldown
		},
		handleSuccessfulVerification(verificationResponse) {
			this.alert.toast('success', this.translate('phone_number_verification_success'));
			this.$emit('verified', verificationResponse.response.token);
			this.hide();
		},
		handleVerificationError() {
			if (UNPROCESSABLE.includes(this.errors.status) && typeof this.errors.errors.mobile_number !== 'undefined') {
				this.errors.errors.mobile_number.forEach((error) => {
					this.alert.toast('error', error);
				});
				this.$emit('phoneValidationError');
				this.hide();
			}
			if (!UNPROCESSABLE.includes(this.errors.status)) {
				this.alert.toast('error', this.translate('default_error_message'));
			}
		},
		clearError(field) {
			if (UNPROCESSABLE.includes(this.errors.status) && typeof this.errors.errors.mobile_number !== 'undefined') {
				this.errors.errors.mobile_number.forEach((error) => {
					this.alert.toast('error', error);
				});
				this.$emit('phoneValidationError');
			}
			if (field && typeof this.phoneVerification.data.errors.errors[field] !== 'undefined') {
				this.$delete(this.phoneVerification.data.errors.errors, field);
			}
		},
		maskPhoneNumber(phoneNumber) {
			try {
				const regexp = /([\d][\d]$)+/;
				const lastTwoDigits = phoneNumber.match(regexp)[0];
				return `******${lastTwoDigits}`;
			} catch (error) {
				return phoneNumber;
			}
		},
		handleOpen(open) {
			if (open) this.show();
			else this.hide();
		},
		show() {
			this.$refs.phoneVerificationModal.show();
			this.$emit('update:open', true);
		},
		hide() {
			this.reset();
			this.$refs.phoneVerificationModal.hide();
			this.$emit('update:open', false);
		},
	},
};
</script>
<style scoped>
	.text-blue {
		color: #13637f !important;
	}
</style>
